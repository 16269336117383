export const projectTypes = {
	PROJECT: 0,
	AUDIT: 1,
}

export const auditTypes = {
	BAO: 0,
	CAPRENOV: 1,
	TRANSFERT_CAPRENOV_VERS_BAO: 2,
	TH145: 3
}
export const auditTypesDefaultPrice = {
	BAO: 550,
	CAPRENOV: 475,
	TRANSFERT_CAPRENOV_VERS_BAO: 100,
	TH145: 1200
}