import MyTable from 'components/MyTable/MyTable';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { collaboratorTypes } from './collaborator.constant';
import StatsTotal from './StatsTotal';
import classes from 'styles/classes.module.scss'
import { useProjects } from 'hooks/useProjects';
import { projectTypes } from './project.constant';
import { columnTypes } from 'components/MyTable/MyTable.constant';

const Statistics = ({
    userType
}) => {
    const navigate = useNavigate()
    const { allProjectsFiltered } = useProjects()

    const propertyName = useMemo(() =>
        userType === collaboratorTypes.AMO
            ? 'auditorUser'
            : userType === collaboratorTypes.INSTALLATEUR
                ? 'regieUser'
                : 'engineerUser',
        [userType])

    const priceName = useMemo(() =>
        userType === collaboratorTypes.AMO
            ? 'priceAuditor'
            : userType === collaboratorTypes.INSTALLATEUR
                ? 'priceInstallator'
                : 'priceEngineer', [userType])

    const users = useMemo(() => allProjectsFiltered.reduce(
        (propertyArr, project) => {
            const obj = project[propertyName]
            if (obj && obj.name) {
                const caHT = parseFloat(project[priceName] || 0)
                const caTTC = (1 + parseFloat(obj.tva)) * caHT
                const indexOfElement = propertyArr.findIndex(({ name }) => name === obj.name)
                if (indexOfElement === -1) {
                    return [
                        ...propertyArr, {
                            ...obj,
                            deposit: (obj?.deposits || []).reduce((prev, { deposit }) => prev + deposit, 0),
                            caHT,
                            caTTC,
                            [project.type === projectTypes.PROJECT ? 'RDVs' : 'Audits']: 1,
                            [project.type === projectTypes.PROJECT ? 'Audits' : 'RDVs']: 0,
                        }]
                } else {
                    propertyArr[indexOfElement].caHT += caHT
                    propertyArr[indexOfElement].caTTC += caTTC
                    propertyArr[indexOfElement][project.type === projectTypes.PROJECT ? 'RDVs' : 'Audits']++
                    return propertyArr
                }
            }
            return propertyArr
        }, []), [allProjectsFiltered, propertyName, priceName])

    const columns = useMemo(() => [
        { title: 'Nom du client', type: columnTypes.TEXT, render: ({ name }) => name, field: 'name' },
        { title: 'CA HT', render: ({ caHT }) => caHT, field: 'caHT' },
        { title: 'TVA', type: columnTypes.TEXT, render: ({ tva }) => tva, field: 'tva' },
        { title: 'CA TTC', render: ({ caTTC }) => caTTC, field: 'caTTC' },
        [collaboratorTypes.AMO, collaboratorTypes.INGENIEUR].includes(userType) && { title: 'Acompte TTC', render: ({ deposit }) => deposit, field: 'deposit' },
        [collaboratorTypes.AMO, collaboratorTypes.INGENIEUR].includes(userType) && { title: 'Total TTC', render: ({ deposit, caTTC }) => caTTC - deposit },
        { title: 'RDV Total', render: ({ RDVs }) => RDVs, field: 'RDVs' },
        [collaboratorTypes.INSTALLATEUR, collaboratorTypes.INGENIEUR].includes(userType) && { title: 'Audits', render: ({ Audits }) => Audits, field: 'Audits' },
    ].filter(e => !!e), [userType])

    const userTypeTitle = useMemo(() =>
        Object
            .entries(collaboratorTypes)
            .find(([, value]) => value === userType)[0], [userType])

    return (
        <div className={classes.marginAuto}>
            <StatsTotal users={users} userType={userType} />
            <div style={{ maxWidth: "100%" }}>
                <MyTable
                    columns={columns}
                    data={users}
                    title={'Statistiques ' + userTypeTitle}
                    options={{
                        paging: true,
                        pageSize: 50
                    }}
                    onRowClick={(event, rowData) => navigate(`/user/${rowData.id}`)}
                />
            </div>
        </div>
    );
};

export default Statistics;