import Button from 'components/Button';
import React, { useCallback, useEffect, useMemo } from 'react';
import classes from '../home/Home.module.scss'
import PeriodSelection from './PeriodSelection';
import { useAuth } from 'hooks/useAuth';
import { useStoreUtils } from 'hooks/useStoreUtils';
import { useApi } from 'hooks/useApi';
import { isAuthenticated } from 'utils/Auth';
import { projectTypes } from './project.constant';

const Header = () => {
	const { state: { user }, isAdmin, isSyntheseUser, isComptaUser, dispatch } = useStoreUtils()
	const { logout } = useAuth()
	const { regenerateToken } = useApi()

	useEffect(() => {
		if (isAuthenticated()) {
			regenerateToken()
		}
	}, [])

	const complements = useMemo(() => {
		let index = 0
		let components = [<Button
			type="black"
			key={index++}
			onClick={() => logout()}
		>
			DÉCONNEXION
		</Button>]
		if (isSyntheseUser) {
			components.push(<Button key={index++} link={'/'}>Faire une nouvelle synthèses</Button>)
			components.push(<Button type='secondary' key={index++} link={'/syntheses'}>Mes synthèses</Button>)
			isAdmin && components.push(<Button type='black' key={index++} link={'/allSyntheses'}>Toutes les syntheses</Button>)
		}
		if (isComptaUser) {
			components.push(<Button type="primary" key={index++} link={'/user/create'}>
				Créer un nouveau collaborateur
			</Button>)
			components.push(<Button type="primary" key={index++} link={'/project/create'}>
				Créer un nouveau projet ou audit
			</Button>)
			components.push(<Button type="secondary" key={index++} link={'/uploadCsv'}>
				Charger le CSV
			</Button>)
			components.push(<Button type="warning" key={index++} link={'/projects'}>
				Mes projets
			</Button>)
			components.push(<Button type="danger" key={index++} link={'/doublons/' + projectTypes.PROJECT}>
				Mes doublons projets
			</Button>)
			components.push(<Button type="danger" key={index++} link={'/doublons/' + projectTypes.AUDIT}>
				Mes doublons audits
			</Button>)
			components.push(<Button type="warning" key={index++} link={'/audits'}>
				Mes audits
			</Button>)
			components.push(<Button type="warning" key={index++} link={'/users'}>
				Mes collaborateurs
			</Button>)
			components.push(<Button type="warning" key={index++} link={'/statistics'}>
				Statistiques
			</Button>)
		}
		if (isAdmin) {
			components.push(<Button type='admin' key={index++} link={'/admin/create'}>Créer un nouvel utilisateur</Button>)
			components.push(<Button type='black' key={index++} link={'/admin/users'}>Toutes les utilisateurs</Button>)
		}
		components.push(<Button type='black' key={index++} link={'/settings'}>Mon compte</Button>)

		return components
	}, [isAdmin, isComptaUser, isSyntheseUser, logout])

	const updateDatesCompta = useCallback(({ startDate, endDate }) => {
		return dispatch({
			type: 'UPDATE_DATES', payload: {
				startDate,
				endDate
			}
		})
	}, [dispatch])
	if (!!!user) {
		return null
	}
	return <div className={classes.header}>
		{isComptaUser && <div>
			<PeriodSelection updateFunc={updateDatesCompta} />
		</div>}
		<div className={classes.buttonsContainer}>
			{complements}
		</div>
	</div>
};

export default Header;