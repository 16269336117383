import { useProjects } from 'hooks/useProjects';
import React, { useMemo } from 'react';
import ProjectsTable from './ProjectsTable';
import classes from '../../styles/classes.module.scss'
import { useParams } from 'react-router-dom';
import { projectTypes } from './project.constant';

const Doublons = () => {
	const type = Number(useParams().type)
	const { setDoublons, doublons } = useProjects()
	const doublonsToDisplay = doublons.filter(project => project.type === type)
	const typeText = useMemo(() => Object.keys(projectTypes)[type], [type])

	return (
		<div>
			<h1 className={classes.taLeft}>Mes Doublons {typeText}</h1>
			<ProjectsTable
				projects={doublonsToDisplay}
				setProjects={setDoublons}
				isAudit={type === projectTypes.AUDIT}
			/>
		</div>
	);
};

export default Doublons;