import React, { useMemo, useState } from 'react';
import ProjectsTable from './ProjectsTable';
import SelectNavigationComponent from 'components/MenuNavigationComponent/SelectNavigationComponent';

const AuditsUserPage = ({
	setProjects,
	auditsFiltered,
	BAO,
	CAPRENOV,
	TRANSFERT_CAPRENOV_VERS_BAO,
	TH145,
	checkBoxFunc,
	staticCheck,
}) => {
	const [filter, setFilter] = useState()
	const audits = useMemo(() => {
		switch (filter) {
			case 1:
				return BAO
			case 2:
				return CAPRENOV
			case 3:
				return TRANSFERT_CAPRENOV_VERS_BAO
			case 4:
				return TH145
			default:
				return auditsFiltered
		}
	}, [auditsFiltered,
		BAO,
		CAPRENOV,
		TRANSFERT_CAPRENOV_VERS_BAO,
		TH145,
		filter])

	return (<div>
		<SelectNavigationComponent
			filter={filter}
			setFilter={setFilter}
			filters={[
				'Tous les audits',
				'BAO',
				'CAPRENOV',
				'TRANSFERT_CAPRENOV_VERS_BAO',
				'TH145',
			]} />
		<ProjectsTable
			title='Audits'
			isAudit
			projects={audits}
			setProjects={setProjects}
			checkBoxFunc={checkBoxFunc}
			staticCheck={staticCheck}
		/>
	</div>
	);
};

export default AuditsUserPage;