import React, { useEffect, useState } from 'react';
import classes from './CheckBoxInput.module.scss'
import classNames from 'classnames';

const CheckBoxInput = ({
	onUpdate = () => { },
	staticCheck = false
}) => {
	const [previousStaticCheck, setPreviousStaticCheck] = useState(staticCheck)
	const [lastCheckValue, setLastCheckValue] = useState(false)
	const [checked, setChecked] = useState(false)
	useEffect(() => {
		if (lastCheckValue !== checked) {
			onUpdate(checked)
			setLastCheckValue(checked)
		}
	}, [checked, onUpdate, lastCheckValue])

	useEffect(() => {
		if (!previousStaticCheck && staticCheck) {
			setChecked(true)
		} else {
			setChecked(false)
		}
		setPreviousStaticCheck(staticCheck)

	}, [staticCheck])

	const onClick = (evt) => {
		evt.stopPropagation()
		evt.preventDefault()
		setChecked(checked => !checked)
	}
	return (
		<div className={classNames(classes.emptySquare, checked && classes.checked)} onClick={onClick}>
			{checked && '✓'}
		</div>
	);
};

export default CheckBoxInput;