import { useProjects } from 'hooks/useProjects';
import React, { useCallback, useMemo, useState } from 'react';
import ProjectsTable from './ProjectsTable';
import classes from '../../styles/classes.module.scss'
import Button from 'components/Button/Button';
import { isEmpty } from 'lodash';
import toast from 'components/toast/toast';
import { useApi } from 'hooks/useApi';

const Projects = () => {
	const  { deleteProjects, updateProject }  = useApi()
	const [loading, setLoading] = useState(false)
	const { projectsFiltered, setProjects } = useProjects()
	const [staticCheck, setStaticCheck] = useState(false)
	const projectsChecked = useMemo(() => projectsFiltered
		.filter(e => !!e.checked)
		.reduce((prev, { id, cp, name }) => ({
			...prev,
			[id]: cp + '	' + name
		}), {})
		, [projectsFiltered])
	const onChecked = useCallback(({ id, cp, name }, checked) => {
		setProjects(projects => {
			const index = projects.findIndex(project => project.id === id)
			projects[index].checked = checked
			return [...projects]
		})
	}, [setProjects])

	const copy = useCallback(async () => {
		setLoading(true)
		try {
			await navigator.clipboard.writeText(Object.values(projectsChecked).join('\n'))
			const entries = Object.entries(projectsChecked)
			for (let index = 0; index < entries.length; index++) {
				const [id] = entries[index];
				await updateProject(id, { status: 3 })
			}
			const keys = Object.keys(projectsChecked).map(Number)
			setProjects(projects => projects.map(project => keys.includes(project.id) ? {
				...project,
				status: 3,
				statusObj: {
					id: 3,
					status: 'Envoie en facturation',
					order: 2,
					color: 'yellow'
				}
			} : project))
			toast.success('Copié dans le presse-papier')
		} catch (error) {
			console.log('error', error)
		} finally {
			setLoading(false)
		}
	}, [projectsChecked, setProjects])


	const remove = useCallback(async () => {
		setLoading(true)
		try {
			const ids = Object.keys(projectsChecked).map(Number)
			await deleteProjects(ids)
			setProjects(projects => projects.filter(project => !ids.includes(project.id)))
			toast.success(ids.length + ' lignes supprimées')
		} catch (error) {
			console.log('error', error)
		} finally {
			setLoading(false)
		}
	}, [projectsChecked, setProjects])

	const checkAll = useCallback(() => {
		setStaticCheck(true)
	}, [setStaticCheck])
	const unCheckAll = useCallback(() => {
		setStaticCheck(false)
		setProjects(projects => projects.map(project => ({ ...project, checked: false })))
	}, [setStaticCheck, setProjects])
	return (
		<div className={classes.relative}>
			<h1 className={classes.taLeft}>Mes Projets</h1>
			<ProjectsTable
				projects={projectsFiltered}
				setProjects={setProjects}
				checkBoxFunc={onChecked}
				staticCheck={staticCheck}
			/>
			<div className={classes.leftBottom}>
				{!isEmpty(projectsChecked) &&<> <Button
					loading={loading}
					onClick={copy}
				>
					Copier les {Object.keys(projectsChecked).length} projets
				</Button>
				<Button
					type='danger'
						loading={loading}
						onClick={remove}
					>
						Suprimer les {Object.keys(projectsChecked).length} projets
					</Button>
				</>}
				{<Button
					type='black'
					// loading={loading}
					onClick={staticCheck ? unCheckAll : checkAll}
				>
					{staticCheck ? "Tout déselectionner" : "Tout selectionner"}
				</Button>}
			</div>
		</div>
	);
};

export default Projects;
