import React from 'react';
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;

const PeriodSelection = ({ updateFunc = () => { } }) => {

	const changeDates = (datesArr) => {
		let startDate = new Date('01/01/1000')
		let endDate = new Date('12/30/20000')
		if (datesArr && datesArr.length === 2) {
			startDate = datesArr[0].startOf('day').toDate()
			endDate = datesArr[1].endOf('day').toDate()
		}
		return updateFunc( {
			startDate,
			endDate
		})
	}

	return (
		<RangePicker
			style={{ width: 'max-content' }}
			onChange={changeDates}
		/>
	);
};

export default PeriodSelection;