import classNames from 'classnames';
import React from 'react';
import classes from './MenuNavigationComponent.module.scss'

const SelectNavigationComponent = ({
	filter = 0,
	setFilter = () => { },
	filters = []
}) => {
	return (
		<div className={classes.menuContainer}>
			{filters.map((title, key) =>
				<div
					key={key}
					onClick={() => setFilter(key)}
					className={classNames(classes.centeredContainer, classes.itemContainer, filter === key && classes.active, classes.greenBerg)}>
					<div
						classes={classes.item}
					>
						{title}
					</div>
				</div>)}
		</div>
	);
};

export default SelectNavigationComponent;