import Button from 'components/Button';
import MenuNavigationComponent from 'components/MenuNavigationComponent';
import { useUser } from 'hooks/useUser';
import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import AddDeposit from './AddDeposit';
import { collaboratorTypes } from './collaborator.constant';
import DepositTable from './DepositTable';
import ProjectsTable from './ProjectsTable';
import TotalPrice from './TotalPrice';
import UpdateUser from './UpdateUser';
import classes from './UserPage.module.scss'
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import toast from 'components/toast/toast';
import AuditsUserPage from './AuditsUserPage';
import { useApi } from 'hooks/useApi';

const UserPage = () => {
	const { deleteProjects, updateProject } = useApi()
	const [loading, setLoading] = useState(false)
	const [staticCheck, setStaticCheck] = useState(false)
	const id = Number(useParams().id)
	const {
		user,
		setUser,
		userTypeTitle,
		projectsFiltered,
		auditsFiltered,
		allProjectsFiltered,
		field,
		deposits,
		setDeposits,
		deposit,
		setProjects,
		isDeletable,
		removeUser,
		BAO,
		CAPRENOV,
		TRANSFERT_CAPRENOV_VERS_BAO,
		TH145
	} = useUser(id)

	const projectsChecked = useMemo(() => projectsFiltered
		.filter(e => !!e.checked)
		.reduce((prev, { id, cp, name }) => ({
			...prev,
			[id]: cp + '	' + name
		}), {})
		, [projectsFiltered])
	const auditsChecked = useMemo(() => auditsFiltered
		.filter(e => !!e.checked)
		.reduce((prev, { id, cp, name }) => ({
			...prev,
			[id]: cp + '	' + name
		}), {})
		, [auditsFiltered])

	const onChecked = useCallback(({ id, cp, name }, checked) => {
		setProjects(projects => {
			const index = projects.findIndex(project => project.id === id)
			projects[index].checked = checked
			return [...projects]
		})
	}, [setProjects])

	const copyProjects = useCallback(async () => {
		setLoading(true)
		try {
			await navigator.clipboard.writeText(Object.values(projectsChecked).join('\n'))
			const entries = Object.entries(projectsChecked)
			for (let index = 0; index < entries.length; index++) {
				const [id] = entries[index];
				await updateProject(id, { status: 3 })
			}
			const keys = Object.keys(projectsChecked).map(Number)
			setProjects(projects => projects.map(project => keys.includes(project.id) ? {
				...project,
				status: 3,
				statusObj: {
					id: 3,
					status: 'Envoie en facturation',
					order: 2,
					color: 'yellow'
				}
			} : project))
			toast.success('Copié dans le presse-papier')
		} catch (error) {
			console.log('error', error)
		} finally {
			setLoading(false)
		}
	}, [projectsChecked, setProjects])

	const removeProjects = useCallback(async () => {
		setLoading(true)
		try {
			const ids = Object.keys(projectsChecked).map(Number)
			await deleteProjects(ids)
			setProjects(projects => projects.filter(project => !ids.includes(project.id)))
			toast.success(ids.length + ' lignes supprimées')
		} catch (error) {
			console.log('error', error)
		} finally {
			setLoading(false)
		}
	}, [projectsChecked, setProjects])

	const removeAudits = useCallback(async () => {
		setLoading(true)
		try {
			const ids = Object.keys(auditsChecked).map(Number)
			await deleteProjects(ids)
			setProjects(projects => projects.filter(project => !ids.includes(project.id)))
			toast.success(ids.length + ' lignes supprimées')
		} catch (error) {
			console.log('error', error)
		} finally {
			setLoading(false)
		}
	}, [auditsChecked, setProjects])

	const copyAudits = useCallback(async () => {
		setLoading(true)
		try {
			await navigator.clipboard.writeText(Object.values(auditsChecked).join('\n'))
			const entries = Object.entries(auditsChecked)
			for (let index = 0; index < entries.length; index++) {
				const [id] = entries[index];
				await updateProject(id, { status: 3 })
			}
			const keys = Object.keys(auditsChecked).map(Number)
			setProjects(projects => projects.map(project => keys.includes(project.id) ? {
				...project,
				status: 3,
				statusObj: {
					id: 3,
					status: 'Envoie en facturation',
					order: 2,
					color: 'yellow'
				}
			} : project))
			toast.success('Copié dans le presse-papier')
		} catch (error) {
			console.log('error', error)
		} finally {
			setLoading(false)
		}
	}, [auditsChecked, setProjects])

	const checkAll = useCallback(() => {
		setStaticCheck(true)
	}, [setStaticCheck])
	const unCheckAll = useCallback(() => {
		setStaticCheck(false)
		setProjects(projects => projects.map(project => ({ ...project, checked: false })))
	}, [setStaticCheck, setProjects])

	const components = useMemo(() => [
		{
			title: 'Projets',
			component: <ProjectsTable
				projects={projectsFiltered}
				setProjects={setProjects}
				checkBoxFunc={onChecked}
				staticCheck={staticCheck}
			/>
		},
		[collaboratorTypes.AMO, collaboratorTypes.INGENIEUR].includes(user?.type) && {
			title: 'Acomptes',
			component: <DepositTable
				deposits={deposits} setDeposits={setDeposits} />,
		},
		[collaboratorTypes.INSTALLATEUR, collaboratorTypes.INGENIEUR].includes(user?.type) && {
			title: 'Audits',
			component: <AuditsUserPage
				auditsFiltered={auditsFiltered}
				title="Audits"
				setProjects={setProjects}
				BAO={BAO}
				CAPRENOV={CAPRENOV}
				TRANSFERT_CAPRENOV_VERS_BAO={TRANSFERT_CAPRENOV_VERS_BAO}
				TH145={TH145}
				checkBoxFunc={onChecked}
				staticCheck={staticCheck}
			/>
		},
	].filter(e => !!e), [
		auditsFiltered,
		deposits,
		projectsFiltered,
		setDeposits,
		setProjects,
		user?.type,
		onChecked,
		staticCheck,
		BAO,
		CAPRENOV,
		TRANSFERT_CAPRENOV_VERS_BAO,
		TH145
	])
	return (
		!!user && <div className={classNames(classes.marginAuto, classes.relative)}>
			{isDeletable && <Button
				type='danger'
				onClick={removeUser}
			>Supprimer l'{userTypeTitle}</Button>}
			<h2>Page de l'{userTypeTitle}</h2>
			<div className={classes.topContainer}>
				<UpdateUser
					userTypeTitle={userTypeTitle}
					user={user}
					setUser={setUser}
				/>
				<div>
					<TotalPrice projects={allProjectsFiltered} field={field} user={user} deposit={deposit} />
					{[collaboratorTypes.AMO, collaboratorTypes.INGENIEUR].includes(user?.type) && <AddDeposit
						userId={id}
						setDeposits={setDeposits}
					/>}
				</div>
			</div>
			<MenuNavigationComponent
				components={components}
			/>
			<div className={classes.leftBottom}>
				{!isEmpty(projectsChecked) && <><Button
					loading={loading}
					onClick={copyProjects}
				>
					Copier les {Object.keys(projectsChecked).length} projets
				</Button>
				<Button
					type='danger'
						loading={loading}
						onClick={removeProjects}
					>
						Suprimer les {Object.keys(projectsChecked).length} projets
					</Button>
				</>}
				{!isEmpty(auditsChecked) && <><Button
					loading={loading}
					onClick={copyAudits}
				>
					Copier les {Object.keys(auditsChecked).length} audits
				</Button>
				<Button
					type='danger'
						loading={loading}
						onClick={removeAudits}
					>
						Suprimer les {Object.keys(auditsChecked).length} audits
					</Button>
				</>}
				{<Button
					type='black'
					// loading={loading}
					onClick={staticCheck ? unCheckAll : checkAll}
				>
					{staticCheck ? "Tout déselectionner" : "Tout selectionner"}
				</Button>}
			</div>
		</div>
	)
};

export default UserPage;