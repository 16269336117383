import MyTable from 'components/MyTable/MyTable';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { collaboratorTypes } from './collaborator.constant';
import classes from 'styles/classes.module.scss'
import { columnTypes } from 'components/MyTable/MyTable.constant';

const UsersTable = ({
    users
}) => {
    const navigate = useNavigate()

    const columns = useMemo(() => [
        { title: 'ID', field: 'id' },
        { title: 'Nom', field: 'name', type: columnTypes.TEXT },
        { title: 'Type', field: 'type', type: columnTypes.TEXT, render: ({ type }) => Object.entries(collaboratorTypes).find(([, value]) => value === type)[0] },
        { title: 'Code', field: 'code', type: columnTypes.TEXT },
        { title: 'TVA', field: 'hasTva', type: columnTypes.TEXT },
    ], [])

    return (
        <div style={{ maxWidth: "100%" }} className={classes.marginAuto}>
            <MyTable
                columns={columns}
                data={users}
                title={'Collaborateurs'}
                options={{
                    paging: true,
                    pageSize: 50
                }}
                onRowClick={(event, rowData) => navigate(`/user/${rowData.id}`)}
            />
        </div>
    );
};

export default UsersTable;