import { useProjects } from 'hooks/useProjects';
import React, { useCallback, useMemo, useState } from 'react';
import ProjectsTable from './ProjectsTable';
import MenuNavigationComponent from 'components/MenuNavigationComponent/MenuNavigationComponent';
import classes from '../../styles/classes.module.scss'
import Button from 'components/Button/Button';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import toast from 'components/toast/toast';
import { useApi } from 'hooks/useApi';

const Audits = () => {
	const { deleteProjects, updateProject } = useApi()
	const [staticCheck, setStaticCheck] = useState(false)
	const [loading, setLoading] = useState(false)
	const {
		auditsFiltered,
		setProjects,
		BAO,
		CAPRENOV,
		TRANSFERT_CAPRENOV_VERS_BAO,
		TH145
	} = useProjects()
	// const [auditsChecked, setAuditChecked] = useState({})
	const auditsChecked = useMemo(() => auditsFiltered
		.filter(e => !!e.checked)
		.reduce((prev, { id, cp, name }) => ({
			...prev,
			[id]: cp + '	' + name
		}), {})
		, [auditsFiltered])
	const onChecked = useCallback(({ id, cp, name }, checked) => {
		setProjects(projects => {
			const index = projects.findIndex(project => project.id === id)
			projects[index].checked = checked
			return [...projects]
		})
	}, [setProjects])
	const copy = useCallback(async () => {
		setLoading(true)
		try {
			await navigator.clipboard.writeText(Object.values(auditsChecked).join('\n'))
			const entries = Object.entries(auditsChecked)
			for (let index = 0; index < entries.length; index++) {
				const [id] = entries[index];
				await updateProject(id, { status: 3 })
			}
			const keys = Object.keys(auditsChecked).map(Number)
			setProjects(projects => projects.map(project => keys.includes(project.id) ? {
				...project,
				status: 3,
				statusObj: {
					id: 3,
					status: 'Envoie en facturation',
					order: 2,
					color: 'yellow'
				}
			} : project))
			toast.success('Copié dans le presse-papier')
		} catch (error) {
			console.log('error', error)
		} finally {
			setLoading(false)
		}
	}, [auditsChecked, setProjects])

	const remove = useCallback(async () => {
		setLoading(true)
		try {
			const ids = Object.keys(auditsChecked).map(Number)
			await deleteProjects(ids)
			setProjects(projects => projects.filter(project => !ids.includes(project.id)))
			toast.success(ids.length + ' lignes supprimées')
		} catch (error) {
			console.log('error', error)
		} finally {
			setLoading(false)
		}
	}, [auditsChecked, setProjects])

	const checkAll = useCallback(() => {
		setStaticCheck(true)
	}, [setStaticCheck])
	const unCheckAll = useCallback(() => {
		setStaticCheck(false)
		setProjects(projects => projects.map(project => ({ ...project, checked: false })))
	}, [setStaticCheck, setProjects])

	const components = useMemo(() => [
		{
			title: 'Tous les audits',
			component:
				<ProjectsTable
					isAudit
					staticCheck={staticCheck}
					checkBoxFunc={onChecked}
					projects={auditsFiltered}
					title={'Audits'}
					setProjects={setProjects} />

		},
		{
			title: 'BAO',
			component: <ProjectsTable
				isAudit
				staticCheck={staticCheck}
				checkBoxFunc={onChecked}
				projects={BAO}
				title={'Audits - BAO'}
				setProjects={setProjects} />
		},
		{
			title: 'CAPRENOV',
			component: <ProjectsTable
				isAudit
				staticCheck={staticCheck}
				checkBoxFunc={onChecked}
				projects={CAPRENOV}
				title={'Audits - CAPRENOV'}
				setProjects={setProjects} />
		},
		{
			title: 'TRANSFERT_CAPRENOV_VERS_BAO',
			component: <ProjectsTable
				isAudit
				staticCheck={staticCheck}
				checkBoxFunc={onChecked}
				projects={TRANSFERT_CAPRENOV_VERS_BAO}
				title={'Audits - TRANSFERT_CAPRENOV_VERS_BAO'}
				setProjects={setProjects} />
		},
		{
			title: 'TH145',
			component: <ProjectsTable
				isAudit
				staticCheck={staticCheck}
				checkBoxFunc={onChecked}
				projects={TH145}
				title={'Audits - TH145'}
				setProjects={setProjects} />
		},
	].filter(e => !!e), [staticCheck, onChecked, auditsFiltered, setProjects, BAO, CAPRENOV, TRANSFERT_CAPRENOV_VERS_BAO, TH145])


	return (
		<div className={classNames(classes.relative, classes.fullWidth)}>
			<MenuNavigationComponent
				components={components}
			/>
			<div className={classes.leftBottom}>
				{!isEmpty(auditsChecked) && <>
					<Button
						loading={loading}
						onClick={copy}
					>
						Copier les {Object.keys(auditsChecked).length} projets
					</Button>
					<Button
						type='danger'
						loading={loading}
						onClick={remove}
					>
						Suprimer les {Object.keys(auditsChecked).length} projets
					</Button>
				</>}
				{<Button
					type='black'
					// loading={loading}
					onClick={staticCheck ? unCheckAll : checkAll}
				>
					{staticCheck ? "Tout déselectionner" : "Tout selectionner"}
				</Button>}
			</div>
		</div>
	);
};

export default Audits;
