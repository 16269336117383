import toast from 'components/toast';
import { parseUser } from 'helpers';
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { collaboratorTypes } from 'views/homeCompta/collaborator.constant'
import { useProjects } from './useProjects';
import { useApi } from './useApi';

export const useUser = (userId) => {
	const { deleteUser, getUser } = useApi()
	const navigate = useNavigate()
	const [user, setUser] = useState()
	const [deposits, setDeposits] = useState([])
	const deposit = useMemo(() => deposits.reduce(
		(prev, { deposit }) => prev + deposit, 0
	), [deposits])

	const orderedDeposits = useMemo(() => deposits.sort(
		(a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
	), [deposits])
	useEffect(() => {
		getUser(userId)
			.then(parseUser)
			.then(setUser)
	}, [userId])

	useEffect(() => {
		if (user) {
			setDeposits(user?.deposits || [])
		}

	}, [user])


	const waiting = useMemo(() => !!!user, [user])

	const {
		allProjects,
		audits,
		projects,
		allProjectsFiltered,
		projectsFiltered,
		auditsFiltered,
		setProjects,
		setDoublons,
		doublons,
		BAO,
		CAPRENOV,
		TRANSFERT_CAPRENOV_VERS_BAO,
		TH145
	} = useProjects(user, waiting)


	const userTypeTitle = useMemo(() => !!user &&
		Object
			.entries(collaboratorTypes)
			.find(([, value]) => value === user?.type)[0], [user])
	const field = useMemo(() => user?.type === collaboratorTypes.AMO ? 'priceAuditor' : 'priceInstallator', [user])

	const removeUser = useCallback(
		async () => {
			try {
				await deleteUser(userId)
				toast.success(`L'${userTypeTitle} a été supprimé.`)
				navigate('/users')
			} catch (error) {
				toast.error(`Erreur durant la suppression de l'${userTypeTitle} : ` + error.message)
			}
		},
		[deleteUser, userId, userTypeTitle, navigate]
	)

	const isDeletable = useMemo(() =>
		allProjectsFiltered.length === 0
		&& deposits.length === 0, [allProjectsFiltered, deposits])

	return {
		user,
		setUser,
		userTypeTitle,
		field,
		allProjects,
		audits,
		projects,
		allProjectsFiltered,
		projectsFiltered,
		auditsFiltered,
		deposits: orderedDeposits,
		setDeposits,
		deposit,
		isDeletable,
		removeUser,
		setProjects,
		setDoublons,
		doublons,
		BAO,
		CAPRENOV,
		TRANSFERT_CAPRENOV_VERS_BAO,
		TH145
	}
}
