import { getEndOfDay, getEndOfMonth, isDateBetween, isFutureMonth } from 'dates'
import { useCallback, useContext, useMemo } from 'react'

import { Store } from '../store/store'

export const useMonth = () => {
	const { state: { month, startDate, endDate } } = useContext(Store)
	const year = useMemo(() => {
		let year = (new Date()).getFullYear()
		if (isFutureMonth(month)) {
			year--
		}
		return year
	}, [month])

	// const startDate = useMemo(() => month > 0
	//     ? new Date(`${month < 10 ? '0' + month : month}/01/${year}`)
	//     : new Date('01/01/1000')
	//     , [month, year])

	// const endDate = useMemo(() => month > 0
	//     ? getEndOfDay(getEndOfMonth(new Date(`${month < 10 ? '0' + month : month}/01/${year}`)))
	//     : new Date('12/30/20000')
	//     , [month, year])
	//renvoie les dates entre 
	//+ mets les plus récents au début (du plus au moins récent)
	const filterByDate = useCallback(
		(elements, propName) => elements
			.filter(element => isDateBetween(
				new Date(element[propName]),
				startDate,
				endDate
			)).sort((first, second) => {
				const firstDate = first[propName] ? new Date(first[propName]) : null
				const secondDate = second[propName] ? new Date(second[propName]) : null
				if (firstDate && secondDate) {
					return secondDate.getTime() - firstDate.getTime()
				}
				if (!firstDate && !secondDate) {
					return 0
				}
				return firstDate ? 1 : -1
			}),
		[startDate, endDate])

	return {
		month,
		startDate,
		endDate,
		filterByDate,
		year
	}
}
