import { useEffect, useMemo, useState } from 'react'
import { projectTypes } from 'views/homeCompta/project.constant'
import { useMonth } from './useMonth'
import { useStoreUtils } from './useStoreUtils'
import { useApi } from './useApi'

export const useProjects = (user, waiting = false) => {
	const { getProjects } = useApi()
	const { filterByDate } = useMonth()
	const { setTableLoadingOn, setTableLoadingOff } = useStoreUtils()
	const [allProjects, setAllProjects] = useState([])
	const [doublons, setDoublons] = useState([])
	const [allProjectsWithDoublons, setAllProjectsWithDoublons] = useState([])
	const audits = useMemo(() => allProjects.filter(({ type }) => type === projectTypes.AUDIT), [allProjects])
	const projects = useMemo(() => allProjects.filter(({ type, doublon }) => type === projectTypes.PROJECT && !doublon), [allProjects])

	useEffect(() => {
		setAllProjects(allProjectsWithDoublons.filter(({ doublon }) => !doublon))
		setDoublons(allProjectsWithDoublons.filter(({ doublon }) => !!doublon))
	}, [allProjectsWithDoublons])

	useEffect(() => {
		if (waiting) {
			setTableLoadingOn()
			return
		}
		setTableLoadingOn()
		getProjects(user?.id, user?.type)
			.then(setAllProjectsWithDoublons)
			.finally(setTableLoadingOff)
	}, [user, waiting])

	const allProjectsFiltered = useMemo(() => filterByDate(
		allProjects,
		'auditDate'
	), [allProjects, filterByDate])
	const projectsFiltered = useMemo(() => filterByDate(
		projects,
		'auditDate'
	), [projects, filterByDate])
	const auditsFiltered = useMemo(() => filterByDate(
		audits,
		'auditDate'
	), [audits, filterByDate])

	const BAO = useMemo(() => auditsFiltered.filter(({ auditType }) => auditType === 'BAO'), [auditsFiltered])
	const CAPRENOV = useMemo(() => auditsFiltered.filter(({ auditType }) => auditType === 'CAPRENOV'), [auditsFiltered])
	const TRANSFERT_CAPRENOV_VERS_BAO = useMemo(() => auditsFiltered.filter(({ auditType }) => auditType === 'TRANSFERT_CAPRENOV_VERS_BAO'), [auditsFiltered])
	const TH145 = useMemo(() => auditsFiltered.filter(({ auditType }) => auditType === 'TH145'), [auditsFiltered])

	return {
		allProjects,
		audits,
		projects,
		allProjectsFiltered,
		projectsFiltered,
		auditsFiltered,
		setProjects: setAllProjects,
		setDoublons,
		doublons,
		BAO,
		CAPRENOV,
		TRANSFERT_CAPRENOV_VERS_BAO,
		TH145
	}
}
